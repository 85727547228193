import React, { useState, useEffect } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import StatisticsCards from 'components/dashboards/sass/stats-cards/StatisticsCards';
import DataTransferService from 'core/service/dataTransferService';
import ImagePreviewComponent from 'components/ImagePreview/ImagePreviewComponent';
import BlobStorageService from 'core/service/StorageService';
import Approved from 'assets/img/Approved.svg';
import { DashBoardService } from 'core/service/dashBoardService';

const DriverDetails = () => {
  const location = useLocation();
  const dashBoardService = new DashBoardService();
  const dataTransferService = new DataTransferService();
  const queryParams = new URLSearchParams(location.search);
  const [formData, setFormData] = useState({ duid: '' });
  const [dynamicRidesValue, setDynamicRidesValue] = useState(0);
  const [dynamicRatingValue, setDynamicRatingValue] = useState(0);
  const [dynamicEarningsValue, setDynamicEarningsValue] = useState(0);
  const setLoading = useState(false)[1];
  const [getDriverDetails, setGetDriverDetails] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const blobStorageService = new BlobStorageService();

  useEffect(() => {
    setFormData({ duid: queryParams.get('duid') || '' });
  }, [location.search]);

  useEffect(() => {
    if (formData.duid) {
      fetchDriverDetails();
    }
  }, [formData.duid]);

  const fetchDriverDetails = async () => {
    try {
      setLoading(true);
      const response = await dashBoardService.fetchDriverDetails(formData.duid);
      if (response.data.responseCode === 200) {
        setLoading(false);
        setGetDriverDetails(response.data.responseBody);
        setDynamicRidesValue(response.data.responseBody.totalRidesCount);
        setDynamicRatingValue(response.data.responseBody.averageRating);
        setDynamicEarningsValue(response.data.responseBody.totalFare);
      }
    } catch {
      setLoading(false);
    }
  };

  const handleImageClick = (images, index) => {
    setPreviewImages(images);
    setCurrentIndex(index);
    setShowPreview(true);
  };

  const handleClose = () => {
    setShowPreview(false);
    setPreviewImages([]);
    setCurrentIndex(0);
  };

  const handlePrev = () => {
    setCurrentIndex(prevIndex =>
      prevIndex > 0 ? prevIndex - 1 : previewImages.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex =>
      prevIndex < previewImages.length - 1 ? prevIndex + 1 : 0
    );
  };

  const aadharCardDocument = getDriverDetails?.driverDocuments?.find(
    doc => doc.documentType === 'Aadhar_Card'
  );
  const panCardDocument = getDriverDetails?.driverDocuments?.find(
    doc => doc.documentType === 'PAN_Card'
  );
  const displayDocument = aadharCardDocument || panCardDocument;
  const documentTitle = aadharCardDocument
    ? 'Aadhar Card'
    : panCardDocument
    ? 'Pan Card'
    : 'Aadhar Card/PAN Card';

  const profilePhotoDocument = getDriverDetails?.driverDocuments?.find(
    doc => doc.documentType === 'Profile_Photo'
  );

  const registrationCertificateDocument =
    getDriverDetails?.driverDocuments?.find(
      doc => doc.documentType === 'Registration_Certificate'
    );
  console.log(registrationCertificateDocument);

  const bankAccountDocument = getDriverDetails?.driverDocuments?.find(
    doc => doc.documentType === 'Bank_Account'
  );
  console.log(bankAccountDocument);
  const drivingLicenseDocument = getDriverDetails?.driverDocuments?.find(
    doc => doc.documentType === 'Driving_Licence'
  );

  return (
    <div className={`driver-details ${showPreview ? 'blur-background' : ''}`}>
      <Card className="ps-3 py-3">
        <p className="text-sky-blue-fw-500-fs-13 mb-0">Driver</p>
        {getDriverDetails && (
          <p className="text-fw-500-fs-23 mb-0">
            {getDriverDetails?.firstName}
          </p>
        )}
      </Card>

      <div className="py-3">
        <StatisticsCards
          ridesValue={dynamicRidesValue}
          ratingValue={dynamicRatingValue}
          earningsValue={dynamicEarningsValue}
        />
      </div>

      <Card className="driverDetails">
        <p className="text-fw-400 fs-19 mb-0 p-3">Details</p>
        <Card.Body className="position-relative bg-color-gray">
          <div className="ps-3">
            <Row>
              <Col lg={2} className="px-0">
                {profilePhotoDocument?.frontImage && (
                  <img
                    src={
                      dataTransferService.getBloburl() +
                      profilePhotoDocument.frontImage
                    }
                    alt="Profile image"
                    className="img-fluid"
                  />
                )}
              </Col>
              <Col lg={10} className="px-3">
                <p className="mb-4 text-fw-500 fs-13">ACCOUNT INFORMATION</p>

                <Row className="mb-2">
                  <Col lg={3}>
                    <p className="text-fw-400 fs-16">Created</p>
                  </Col>
                  <Col lg={9}>
                    <p className="text-fw-400 fs-16">
                      {blobStorageService.formatDate(
                        getDriverDetails?.createdTime
                      )}
                    </p>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col lg={3}>
                    <p className="text-fw-400 fs-16">Name</p>
                  </Col>
                  <Col lg={9}>
                    <p className="text-fw-400 fs-16">
                      {getDriverDetails?.firstName}
                    </p>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col lg={3}>
                    <p className="text-fw-400 fs-16">Gender</p>
                  </Col>
                  <Col lg={9}>
                    <p className="text-fw-400 fs-16">
                      {getDriverDetails?.gender}
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col lg={3}>
                    <p className="mb-0 text-fw-400 fs-16">Phone number</p>
                  </Col>
                  <Col lg={9}>
                    <p className="mb-0 text-fw-400 fs-16">
                      {getDriverDetails?.mobileNumber}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>

      <Card className="driverDetails my-3">
        <Card.Header className="d-flex justify-content-between p-3">
          <p className="text-fw-400 fs-19 mb-0" style={{ color: '#000' }}>
            Bank Account
          </p>
          {bankAccountDocument && <img src={Approved} alt="Approved" />}
        </Card.Header>
        <Card.Body className="position-relative bg-color-gray">
          {bankAccountDocument?.frontImage ? (
            <div className="pt-2">
              <p className="text-fw-400 fs-16 mb-2">
                Account Holder Name: {bankAccountDocument?.accountHolderName}
              </p>
              <p className="text-fw-400 fs-16 mb-2">
                Account Number: {bankAccountDocument?.accountNumber}
              </p>
              <p className="text-fw-400 fs-16 mb-2">
                IFSC Code: {bankAccountDocument?.ifscCode}
              </p>
              <p
                className="text-fw-400 fs-16 fw-bold mb-3"
                style={{ color: '#1B1D1F' }}
              >
                Cancelled Cheque/Bank Statement/Bank Passbook
              </p>

              <div className="d-flex gap-3">
                <Col lg="6">
                  {bankAccountDocument?.frontImage ? (
                    <img
                      className="w-100 ht-277 pt-3"
                      src={
                        dataTransferService.getBloburl() +
                        bankAccountDocument.frontImage
                      }
                      alt="Front image"
                      onClick={() =>
                        handleImageClick(
                          [
                            dataTransferService.getBloburl() +
                              bankAccountDocument.frontImage
                          ],
                          0
                        )
                      }
                    />
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: '100px' }}
                    >
                      <p className="mb-0 text-fw-400 fs-16">NO DATA FOUND</p>
                    </div>
                  )}
                </Col>
              </div>
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '100px' }}
            >
              <p className="mb-0 text-fw-400-fs-16">NO DATA FOUND</p>
            </div>
          )}
        </Card.Body>
      </Card>

      <Card className="driverDetails my-3">
        <Card.Header className="d-flex justify-content-between p-3">
          <p className="text-fw-400 fs-19 mb-0" style={{ color: '#000' }}>
            {documentTitle}
          </p>

          {displayDocument && <img src={Approved} alt="Approved" />}
        </Card.Header>
        <Card.Body className="position-relative bg-color-gray">
          {displayDocument &&
          (displayDocument.frontImage || displayDocument.backImage) ? (
            <div className="pt-2">
              <div className="mb-2">
                <p className="text-fw-400 fs-16 mb-2">
                  Card type: {displayDocument?.documentType}
                </p>
                <p className="text-fw-400 fs-16 mb-2">
                  Number: {displayDocument?.documentNumber}
                </p>
              </div>
              <Row>
                <Col>
                  {displayDocument?.frontImage ? (
                    <img
                      className="w-100 ht-277 pt-3"
                      src={
                        dataTransferService.getBloburl() +
                        displayDocument.frontImage
                      }
                      alt="Front image"
                      onClick={() =>
                        handleImageClick(
                          [
                            dataTransferService.getBloburl() +
                              displayDocument.frontImage
                          ],
                          0
                        )
                      }
                    />
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: '100px' }}
                    >
                      <p className="mb-0 text-fw-400 fs-16">NO DATA FOUND</p>
                    </div>
                  )}
                </Col>
                <Col>
                  {displayDocument?.backImage ? (
                    <img
                      className="w-100 ht-277 pt-3"
                      src={
                        dataTransferService.getBloburl() +
                        displayDocument.backImage
                      }
                      alt="Back image"
                      onClick={() =>
                        handleImageClick(
                          [
                            dataTransferService.getBloburl() +
                              displayDocument.backImage
                          ],
                          0
                        )
                      }
                    />
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: '100px' }}
                    >
                      <p className="mb-0 text-fw-400 fs-16">NO DATA FOUND</p>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '100px' }}
            >
              <p className="mb-0 text-fw-400-fs-16">NO DATA FOUND</p>
            </div>
          )}
        </Card.Body>
      </Card>

      <Card className="driverDetails my-3">
        <Card.Header className="d-flex justify-content-between p-3">
          <p className="text-fw-400 fs-19 mb-0" style={{ color: '#000' }}>
            Driving License
          </p>
          {drivingLicenseDocument && <img src={Approved} alt="Approved" />}
        </Card.Header>
        <Card.Body className="position-relative bg-color-gray">
          {drivingLicenseDocument?.frontImage ||
          drivingLicenseDocument?.backImage ? (
            <div className="pt-3">
              <p className="text-fw-400 fs-16 mb-4">
                Driving License: {drivingLicenseDocument?.documentNumber}
              </p>
              <Row>
                <Col>
                  {drivingLicenseDocument?.frontImage ? (
                    <img
                      className="w-100 ht-277 pt-3"
                      src={
                        dataTransferService.getBloburl() +
                        drivingLicenseDocument.frontImage
                      }
                      alt="Front image"
                      onClick={() =>
                        handleImageClick(
                          [
                            dataTransferService.getBloburl() +
                              drivingLicenseDocument.frontImage
                          ],
                          0
                        )
                      }
                    />
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: '100px' }}
                    >
                      <p className="mb-0 text-fw-400 fs-16">NO DATA FOUND</p>
                    </div>
                  )}
                </Col>
                <Col>
                  {drivingLicenseDocument?.backImage ? (
                    <img
                      className="w-100 ht-277 pt-3"
                      src={
                        dataTransferService.getBloburl() +
                        drivingLicenseDocument.backImage
                      }
                      alt="Back image"
                      onClick={() =>
                        handleImageClick(
                          [
                            dataTransferService.getBloburl() +
                              drivingLicenseDocument.backImage
                          ],
                          0
                        )
                      }
                    />
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: '100px' }}
                    >
                      <p className="mb-0 text-fw-400 fs-16">NO DATA FOUND</p>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '100px' }}
            >
              <p className="mb-0 text-fw-400-fs-16">NO DATA FOUND</p>
            </div>
          )}
        </Card.Body>
      </Card>

      <Card className="driverDetails my-3">
        <Card.Header className="d-flex justify-content-between p-3">
          <p className="text-fw-400 fs-19 mb-0" style={{ color: '#000' }}>
            Registration Certificate
          </p>
          {registrationCertificateDocument?.frontImage ||
          registrationCertificateDocument?.backImage ? (
            <img src={Approved} alt="Approved" />
          ) : null}
        </Card.Header>
        <Card.Body className="position-relative bg-color-gray">
          {registrationCertificateDocument &&
          (registrationCertificateDocument.frontImage ||
            registrationCertificateDocument.backImage ||
            (!registrationCertificateDocument.isSelfOwned &&
              registrationCertificateDocument.vehicleNumber)) ? (
            <div className="pt-2">
              <div className="mb-2">
                {registrationCertificateDocument.isSelfOwned === false ||
                registrationCertificateDocument.frontImage ||
                registrationCertificateDocument.backImage ? (
                  <>
                    <p className="text-fw-400 fs-16 mb-2">
                      Vehicle type:{' '}
                      {registrationCertificateDocument.isSelfOwned
                        ? 'Self-Owned'
                        : 'Rental'}
                    </p>
                    <p className="text-fw-400 fs-16 mb-2">
                      Vehicle number:{' '}
                      {registrationCertificateDocument.vehicleNumber}
                    </p>
                  </>
                ) : null}
              </div>
              {!registrationCertificateDocument.frontImage &&
              !registrationCertificateDocument.backImage &&
              registrationCertificateDocument.isSelfOwned === false ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: '100px' }}
                >
                  <p className="mb-0 text-fw-400 fs-16">NO DATA FOUND</p>
                </div>
              ) : (
                <Row>
                  <Col>
                    {registrationCertificateDocument?.frontImage ? (
                      <img
                        className="w-100 ht-277 pt-3"
                        src={
                          dataTransferService.getBloburl() +
                          registrationCertificateDocument.frontImage
                        }
                        alt="Front image"
                        onClick={() =>
                          handleImageClick(
                            [
                              dataTransferService.getBloburl() +
                                registrationCertificateDocument.frontImage
                            ],
                            0
                          )
                        }
                      />
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: '100px' }}
                      >
                        <p className="mb-0 text-fw-400 fs-16">NO DATA FOUND</p>
                      </div>
                    )}
                  </Col>
                  <Col>
                    {registrationCertificateDocument?.backImage ? (
                      <img
                        className="w-100 ht-277 pt-3"
                        src={
                          dataTransferService.getBloburl() +
                          registrationCertificateDocument.backImage
                        }
                        alt="Back image"
                        onClick={() =>
                          handleImageClick(
                            [
                              dataTransferService.getBloburl() +
                                registrationCertificateDocument.backImage
                            ],
                            0
                          )
                        }
                      />
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: '100px' }}
                      >
                        <p className="mb-0 text-fw-400 fs-16">NO DATA FOUND</p>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '100px' }}
            >
              <p className="mb-0 text-fw-400-fs-16">NO DATA FOUND</p>
            </div>
          )}
        </Card.Body>
      </Card>

      <ImagePreviewComponent
        show={showPreview}
        images={previewImages}
        currentIndex={currentIndex}
        handleClose={handleClose}
        handlePrev={handlePrev}
        handleNext={handleNext}
      />
    </div>
  );
};

export default DriverDetails;
