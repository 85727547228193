import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import DataTransferService from 'core/service/dataTransferService';

const ProfileDropdown = () => {
  const dataTransferService = new DataTransferService();

  function handleSubmit() {
    dataTransferService.logout();
  }

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle bsPrefix="toggle" className="dropdown-custom">
        <Avatar src={dataTransferService.setdefualtProfileImage()} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item
            className="fw-bold text-warning"
            href="#!"
          ></Dropdown.Item>
          <Dropdown.Item onClick={handleSubmit} as={Link} to="/logout">
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
