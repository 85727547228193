import './dashboard.scss';
import React, { useEffect, useState } from 'react';
import TotalRidesIcon from 'assets/img/total-rides-icon.png';
import TotalDriversIcon from 'assets/img/total-driver-icon.png';
import CabImage from 'assets/img/bike-image-svg.svg';
import SubtleBadge, { statusByColor } from 'components/common/SubtleBadge';
import { Card, Row, Col } from 'react-bootstrap';
import NoActiveDrivers from './../../../assets/img/drivers-not-found-svg.svg';
import EmptyWallet from './../../../assets/img/empty-wallet.png';
import DataTransferService from 'core/service/dataTransferService';
import {
  toPascalWord,
  todayDate
} from 'components/commonComponent/commonComponents';
import Divider from 'components/common/Divider';
import { handleErrorResponse } from 'core/tokenInterceptor/AxiosInstance';
import Avatar from 'components/common/Avatar';
import { DashBoardService } from 'core/service/dashBoardService';

const Dashboard = () => {
  const dashboardService = new DashBoardService();
  const dataTransferService = new DataTransferService();
  const [dashboardInfoResponse, setDashboardInfoResponse] = useState(null);
  const [activeDriverResponse, setActiveDriversResponse] = useState(null);
  const [manageRideHistoryResponse, setManageRideHistoryResponse] =
    useState(null);
  const pageIndex = 0;
  const pageItems = 5;

  useEffect(() => {
    fetchDashboardInfo();
    activeDrivers();
    manageRideHistory();
  }, []);

  const fetchDashboardInfo = () => {
    dashboardService
      .getCount()
      .then(response => {
        if (response.data.responseCode === 200) {
          setDashboardInfoResponse(response);
          console.log(dashboardInfoResponse);
        }
      })
      .catch(error => {
        handleErrorResponse('fetchHubInfo', error.response);
      });
  };

  const activeDrivers = () => {
    dashboardService
      .approvedDrivers(pageIndex, pageItems, 'ONLINE')
      .then(response => {
        if (response.data.responseCode === 200) {
          console.log(response.data.responseBody.searchResult);
          setActiveDriversResponse(response.data.responseBody.searchResult);
          console.log(activeDriverResponse);
        }
      })
      .catch(error => {
        handleErrorResponse('activeDrivers', error.response);
      });
  };

  const manageRideHistory = () => {
    const rideStatus = [
      'CANCELLED',
      'COMPLETED',
      'ASSIGNED',
      'ARRIVED',
      'ONBOARD'
    ];
    const paymentMethod = ['CASH', 'UPI'];
    dashboardService
      .fetchDashboardRideHistory(
        pageIndex,
        pageItems,
        rideStatus.join(','),
        paymentMethod.join(','),
        todayDate()
      )
      .then(response => {
        if (response.data.responseCode === 200) {
          setManageRideHistoryResponse(response.data.responseBody.searchResult);
        }
      })
      .catch(error => {
        handleErrorResponse('manageRideHistory', error.response);
      });
  };

  return (
    <div className="px-3">
      <div className="hub-name">
        <Card className="ps-3 pt-3 card-background-color">
          <Row>
            <Col className="p-0" lg={6}>
              <p className="white ps-3 fs-23-fw-600">Transcil</p>
            </Col>
            <Col className="p-0" lg={6}>
              <img className="vehicle-image" src={CabImage} alt="vehicle"></img>
            </Col>
          </Row>
        </Card>
      </div>

      <div className="hub-info pt-3">
        <Card className="p-3">
          <Row>
            <Col
              className="d-flex flex-column align-items-center justify-content-center position-relative"
              lg={6}
            >
              <img src={TotalDriversIcon} alt="driver-profile" />
              <p className="pt-3">
                {dashboardInfoResponse ? (
                  <>
                    <span className="fs-23-fw-500">
                      {dashboardInfoResponse.data.responseBody.totalDrivers}
                    </span>
                    <span className="fs-23-fw-400"> Drivers</span>
                  </>
                ) : (
                  'Loading...'
                )}
              </p>
              <div className="vertical-line"></div>
            </Col>
            <Col
              className="d-flex flex-column align-items-center justify-content-center"
              lg={6}
            >
              <img src={TotalRidesIcon} alt="ride-profile" />
              <p className="pt-3">
                {dashboardInfoResponse ? (
                  <>
                    <span className="fs-23-fw-500">
                      {dashboardInfoResponse.data.responseBody.totalRides}
                    </span>
                    <span className="fs-23-fw-400"> Rides</span>
                  </>
                ) : (
                  'Loading...'
                )}
              </p>
            </Col>
          </Row>
        </Card>
      </div>

      <div className="recent-rides pt-3">
        <Card>
          <Card.Header className="ghost-white">
            <h6 className="fs-13-fw-500 ">Recent rides</h6>
          </Card.Header>

          <Card.Body>
            {manageRideHistoryResponse ? (
              <>
                <Row className="pb-4 fs-13-fw-500">
                  <Col lg={3}>Ride ID</Col>
                  <Col lg={3}>Driver ID</Col>
                  <Col lg={2}>Vehicle number</Col>
                  <Col lg={2}>Status</Col>
                  <Col lg={2}>Amount</Col>
                </Row>
                <Divider />

                {manageRideHistoryResponse.slice(0, 5).map((ride, index) => (
                  <div key={ride.id || index}>
                    <Row className="pt-4 pb-4 fs-13-fw-500">
                      <Col lg={3}>{ride.ruid}</Col>
                      <Col lg={3}>{ride.driverDetailsResponseDTO.duid}</Col>
                      <Col lg={2}>{ride.vehicleDetailsDTO.vehicleNumber}</Col>
                      <Col lg={2}>
                        <SubtleBadge pill bg={statusByColor(ride.rideStatus)}>
                          {toPascalWord(ride.rideStatus)}
                        </SubtleBadge>
                      </Col>
                      <Col lg={2}>
                        {' '}
                        {ride.rideStatus === 'CANCELLED'
                          ? '₹ 0'
                          : `₹ ${ride.fareAfterDiscount || ride.fare}`}
                      </Col>
                    </Row>
                    {index <
                      manageRideHistoryResponse.slice(0, 5).length - 1 && (
                      <Divider />
                    )}
                  </div>
                ))}
              </>
            ) : (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <img src={EmptyWallet} alt="no-ride-history" />
                <h6 className="fs-13-fw-500 pt-4">Data not available</h6>
              </div>
            )}
          </Card.Body>

          <Card.Footer className="d-flex justify-content-end ghost-white">
            <a href="manage-rides">view all &gt;</a>
          </Card.Footer>
        </Card>
      </div>

      <div className="activeDrivers&hub-vehicles pt-3">
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Header className="ghost-white">
                <Row>
                  <Col lg={10}>
                    <h6>Approved Drivers</h6>
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body className="custom-card-body">
                {activeDriverResponse ? (
                  activeDriverResponse.slice(0, 5).map((driver, index) => (
                    <Row
                      className={`driver-row ${index > 0 ? 'pt-3' : ''}`}
                      key={driver.duid || index}
                    >
                      <Col className="pe-0" lg={1}>
                        <div className="profile-frame">
                          <Avatar
                            src={`${dataTransferService.getBloburl()}${
                              driver.profile_image
                            }`}
                            className="status-online"
                            alt="active-driver"
                            size="2xl"
                          />
                          <span className="active-circle-indicator"></span>
                        </div>
                      </Col>
                      <Col className="ps-0" lg={8}>
                        <p className="fs-13-fw-700">{driver.firstName}</p>
                        <p className="fs-11-fw-600">
                          {driver.vehicle_number ? driver.vehicle_number : '--'}
                        </p>
                      </Col>
                    </Row>
                  ))
                ) : (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <img
                      className="image-style"
                      src={NoActiveDrivers}
                      alt="no-active-driver"
                    />
                    <h6 className="fs-13-fw-500">Data not available</h6>
                  </div>
                )}
              </Card.Body>

              <Card.Footer className="text-center ghost-white">
                <a href="/manage-drivers/approved-drivers">view all &gt;</a>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;
