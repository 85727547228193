import React, { useState, useEffect } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import DataTransferService from 'core/service/dataTransferService';
import ImagePreviewComponent from 'components/ImagePreview/ImagePreviewComponent';
import BlobStorageService from 'core/service/StorageService';
import Header from 'components/common/Header';
import { DashBoardService } from 'core/service/dashBoardService';
import { DocumentStatusButton } from 'components/commonComponent/commonComponents';
import { toast } from 'react-toastify';

function UnapprovedDetails() {
  const location = useLocation();
  const dashBoardService = new DashBoardService();
  const [setDocumentStatus] = useState({});
  const dataTransferService = new DataTransferService();
  const queryParams = new URLSearchParams(location.search);
  const [formData, setFormData] = useState({ duid: '' });
  const setLoading = useState(false)[1];
  const [getDriverDetails, setGetDriverDetails] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const blobStorageService = new BlobStorageService();

  useEffect(() => {
    setFormData({ duid: queryParams.get('duid') || '' });
  }, [location.search]);

  useEffect(() => {
    if (formData.duid) {
      fetchDriverDetails();
    }
  }, [formData.duid]);

  const fetchDriverDetails = async () => {
    try {
      setLoading(true);
      const response = await dashBoardService.fetchDriverDetails(formData.duid);
      if (response.data.responseCode === 200) {
        setLoading(false);
        setGetDriverDetails(response.data.responseBody);
        const statusObj = {};
        response.data.responseBody.driverDocuments.forEach(doc => {
          statusObj[doc.documentType] = doc.documentStatus;
        });
        setDocumentStatus(statusObj);
      }
    } catch {
      setLoading(false);
    }
  };
  const handleImageClick = (images, index) => {
    setPreviewImages(images);
    setCurrentIndex(index);
    setShowPreview(true);
  };

  const handleClose = () => {
    setShowPreview(false);
    setPreviewImages([]);
    setCurrentIndex(0);
  };

  const handlePrev = () => {
    setCurrentIndex(prevIndex =>
      prevIndex > 0 ? prevIndex - 1 : previewImages.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex =>
      prevIndex < previewImages.length - 1 ? prevIndex + 1 : 0
    );
  };
  const getDocumentByType = type => {
    return (
      getDriverDetails?.driverDocuments?.find(
        doc => doc.documentType === type
      ) || {}
    );
  };

  const bankAccountDocument = getDocumentByType('Bank_Account');
  const drivingLicenseDocument = getDocumentByType('Driving_Licence');
  const profilePhotoDocument = getDocumentByType('Profile_Photo');
  const registrationCertificateDocument = getDocumentByType(
    'Registration_Certificate'
  );
  const aadharCardDocument = getDocumentByType('Aadhar_Card');
  const panCardDocument = getDocumentByType('PAN_Card');

  const isAadharCardValid =
    aadharCardDocument && Object.keys(aadharCardDocument).length > 0;
  const isPanCardValid =
    panCardDocument && Object.keys(panCardDocument).length > 0;

  const displayDocument = isAadharCardValid
    ? aadharCardDocument
    : isPanCardValid
    ? panCardDocument
    : null;
  const documentTitle = isAadharCardValid
    ? 'Aadhar Card'
    : isPanCardValid
    ? 'Pan Card'
    : 'Aadhar Card/PAN Card';

  const handleReject = async document => {
    await updateDocumentStatus(document.documentType, 'REJECTED');
    setGetDriverDetails(prevDetails => ({
      ...prevDetails,
      driverDocuments: prevDetails.driverDocuments.map(doc =>
        doc.documentType === document.documentType
          ? { ...doc, documentStatus: 'REJECTED' }
          : doc
      )
    }));
    toast.error('Document rejected');
  };

  const handleApprove = async document => {
    await updateDocumentStatus(document.documentType, 'APPROVED');
    setGetDriverDetails(prevDetails => ({
      ...prevDetails,
      driverDocuments: prevDetails.driverDocuments.map(doc =>
        doc.documentType === document.documentType
          ? { ...doc, documentStatus: 'APPROVED' }
          : doc
      )
    }));
    toast.success('Document approved');
  };
  const updateDocumentStatus = (documentType, newStatus) => {
    setLoading(true);
    const requestData = {
      documentType: documentType,
      documentStatus: newStatus
    };
    dashBoardService
      .updateDocumentStatus(formData.duid, requestData)
      .then(response => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setDocumentStatus(prevStatus => ({
            ...prevStatus,
            [documentType]: newStatus
          }));
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
      });
  };
  return (
    <div className={`driver-details ${showPreview ? 'blur-background' : ''}`}>
      <Header
        breadcrumb="Dashboard/Manage Drivers/Unapproved Drivers"
        title="Drivers"
      />
      <Card className="driverDetails">
        <p className="text-fw-400-fs-19 mb-0 p-3">Details</p>
        <Card.Body className="position-relative bg-color-gray">
          <div className="ps-3">
            <p className="mb-0 text-fw-500-fs-13">ACCOUNT INFORMATION</p>

            <div className="pt-3">
              <Row>
                <Col lg={2}>
                  <p className="mb-0 text-fw-400-fs-16">Created</p>
                </Col>
                <Col lg={10}>
                  <p className="mb-0 text-fw-400-fs-16">
                    {blobStorageService.formatDate(
                      getDriverDetails?.createdTime
                    )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg={2}>
                  <p className="mb-0 text-fw-400-fs-16">Name</p>
                </Col>
                <Col lg={10}>
                  <p className="mb-0 text-fw-400-fs-16">
                    {getDriverDetails?.firstName}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg={2}>
                  <p className="mb-0 text-fw-400-fs-16">Gender</p>
                </Col>
                <Col lg={10}>
                  <p className="mb-0 text-fw-400-fs-16">
                    {getDriverDetails?.gender}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg={2}>
                  <p className="mb-0 text-fw-400-fs-16">Phone number</p>
                </Col>
                <Col lg={10}>
                  <p className="mb-0 text-fw-400-fs-16">
                    {getDriverDetails?.mobileNumber}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Card.Body>
      </Card>
      <div className="pt-3">
        <Row className="align-items-stretch">
          <Col>
            <Card className="driverDetails h-100">
              <Card.Header className="d-flex justify-content-between p-3">
                <p className="text-fw-400 fs-19 mb-0" style={{ color: '#000' }}>
                  Profile photo
                </p>
                {profilePhotoDocument?.frontImage ? (
                  <div className="d-flex gap-2">
                    <DocumentStatusButton
                      document={getDocumentByType('Profile_Photo')}
                      handleApprove={handleApprove}
                      handleReject={handleReject}
                    />
                  </div>
                ) : null}
              </Card.Header>
              <Card.Body className="position-relative bg-color-gray">
                {profilePhotoDocument?.frontImage ? (
                  <div className="pt-3">
                    <img
                      className="w-100 ht-277"
                      src={
                        dataTransferService.getBloburl() +
                        profilePhotoDocument.frontImage
                      }
                      alt="Front image"
                      onClick={() =>
                        handleImageClick(
                          [
                            dataTransferService.getBloburl() +
                              profilePhotoDocument.frontImage
                          ],
                          0
                        )
                      }
                    />
                  </div>
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '100px' }}
                  >
                    <p className="mb-2 text-fw-400 fs-16">NO DATA FOUND</p>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="driverDetails h-100">
              <Card.Header className="d-flex justify-content-between p-3">
                <p className="text-fw-400 fs-19 mb-0" style={{ color: '#000' }}>
                  Bank Account
                </p>
                {bankAccountDocument?.frontImage ||
                bankAccountDocument?.backImage ? (
                  <DocumentStatusButton
                    document={getDocumentByType('Bank_Account')}
                    handleApprove={handleApprove}
                    handleReject={handleReject}
                  />
                ) : null}
              </Card.Header>
              <Card.Body className="position-relative bg-color-gray">
                {bankAccountDocument?.frontImage ? (
                  <div className="pt-2">
                    <p className="text-fw-400 fs-16 mb-2">
                      Account Holder Name:{' '}
                      {bankAccountDocument?.accountHolderName}
                    </p>
                    <p className="text-fw-400 fs-16 mb-2">
                      Account Number: {bankAccountDocument?.accountNumber}
                    </p>
                    <p className="text-fw-400 fs-16 mb-2">
                      IFSC Code: {bankAccountDocument?.ifscCode}
                    </p>
                    <p
                      className="text-fw-400 fs-16 fw-bold mb-2"
                      style={{ color: '#1B1D1F' }}
                    >
                      Cancelled Cheque/Bank Statement/Bank Passbook
                    </p>

                    {bankAccountDocument?.frontImage ? (
                      <img
                        className="w-100 ht-277 pt-3"
                        src={
                          dataTransferService.getBloburl() +
                          bankAccountDocument.frontImage
                        }
                        alt="Front image"
                        onClick={() =>
                          handleImageClick(
                            [
                              dataTransferService.getBloburl() +
                                bankAccountDocument.frontImage
                            ],
                            0
                          )
                        }
                      />
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: '100px' }}
                      >
                        <p className="mb-2 text-fw-400 fs-16">NO DATA FOUND</p>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '100px' }}
                  >
                    <p className="mb-0 text-fw-400-fs-16">NO DATA FOUND</p>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <Card className="driverDetails my-3">
        <Card.Header className="d-flex justify-content-between p-3">
          <p className="text-fw-400 fs-19 mb-0" style={{ color: '#000' }}>
            Driving License
          </p>
          {drivingLicenseDocument?.frontImage ||
          drivingLicenseDocument?.backImage ? (
            <DocumentStatusButton
              document={getDocumentByType('Driving_Licence')}
              handleApprove={handleApprove}
              handleReject={handleReject}
            />
          ) : null}
        </Card.Header>
        <Card.Body className="position-relative bg-color-gray">
          {drivingLicenseDocument?.frontImage ||
          drivingLicenseDocument?.backImage ? (
            <div className="pt-2">
              <p className="text-fw-400 fs-16 mb-2">
                Driving License: {drivingLicenseDocument?.documentNumber}
              </p>
              <Row>
                <Col>
                  <p
                    className="text-fw-400-fs-16 fw-bold mb-2"
                    style={{ color: '#1B1D1F' }}
                  >
                    Upload front image
                  </p>
                  {drivingLicenseDocument?.frontImage ? (
                    <img
                      className="ht-277 pt-3 w-100"
                      src={
                        dataTransferService.getBloburl() +
                        drivingLicenseDocument.frontImage
                      }
                      alt="Front image"
                      onClick={() =>
                        handleImageClick(
                          [
                            dataTransferService.getBloburl() +
                              drivingLicenseDocument.frontImage
                          ],
                          0
                        )
                      }
                    />
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: '100px' }}
                    >
                      <p className="mb-0 text-fw-400-fs-16">NO DATA FOUND</p>
                    </div>
                  )}
                </Col>
                <Col>
                  <p
                    className="text-fw-400-fs-16 fw-bold mb-2"
                    style={{ color: '#1B1D1F' }}
                  >
                    Upload back image
                  </p>
                  {drivingLicenseDocument?.backImage ? (
                    <img
                      className="ht-277 pt-3 w-100"
                      src={
                        dataTransferService.getBloburl() +
                        drivingLicenseDocument.backImage
                      }
                      alt="Back image"
                      onClick={() =>
                        handleImageClick(
                          [
                            dataTransferService.getBloburl() +
                              drivingLicenseDocument.backImage
                          ],
                          0
                        )
                      }
                    />
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: '100px' }}
                    >
                      <p className="mb-0 text-fw-400-fs-16">NO DATA FOUND</p>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '100px' }}
            >
              <p className="mb-0 text-fw-400-fs-16">NO DATA FOUND</p>
            </div>
          )}
        </Card.Body>
      </Card>

      <Card className="driverDetails my-3">
        <Card.Header className="d-flex justify-content-between p-3">
          <p className="text-fw-400 fs-19 mb-0" style={{ color: '#000' }}>
            Registration Certificate
          </p>
          {registrationCertificateDocument?.frontImage ||
          registrationCertificateDocument?.backImage ? (
            <DocumentStatusButton
              document={getDocumentByType('Registration_Certificate')}
              handleApprove={handleApprove}
              handleReject={handleReject}
            />
          ) : null}
        </Card.Header>
        <Card.Body className="position-relative bg-color-gray">
          {registrationCertificateDocument &&
          (registrationCertificateDocument.frontImage ||
            registrationCertificateDocument.backImage ||
            (!registrationCertificateDocument.isSelfOwned &&
              registrationCertificateDocument.vehicleNumber)) ? (
            <div className="pt-2">
              <div className="mb-2">
                {registrationCertificateDocument.isSelfOwned === false ||
                registrationCertificateDocument.frontImage ||
                registrationCertificateDocument.backImage ? (
                  <>
                    <p className="text-fw-400 fs-16 mb-2">
                      Vehicle type:{' '}
                      {registrationCertificateDocument.isSelfOwned
                        ? 'Self-Owned'
                        : 'Rental'}
                    </p>
                    <p className="text-fw-400 fs-16 mb-2">
                      Vehicle number:{' '}
                      {registrationCertificateDocument.vehicleNumber}
                    </p>
                  </>
                ) : null}
              </div>
              {!registrationCertificateDocument.frontImage &&
              !registrationCertificateDocument.backImage &&
              registrationCertificateDocument.isSelfOwned === false ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: '100px' }}
                >
                  <p className="mb-0 text-fw-400 fs-16">NO DATA FOUND</p>
                </div>
              ) : (
                <Row>
                  <Col>
                    <p
                      className="text-fw-400-fs-16 fw-bold mb-2"
                      style={{ color: '#1B1D1F' }}
                    >
                      Upload front image
                    </p>
                    {registrationCertificateDocument.frontImage ? (
                      <img
                        className="ht-277 pt-3 w-100"
                        src={
                          dataTransferService.getBloburl() +
                          registrationCertificateDocument.frontImage
                        }
                        alt="Front image"
                        onClick={() =>
                          handleImageClick(
                            [
                              dataTransferService.getBloburl() +
                                registrationCertificateDocument.frontImage
                            ],
                            0
                          )
                        }
                      />
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: '100px' }}
                      >
                        <p className="mb-0 text-fw-400 fs-16">NO DATA FOUND</p>
                      </div>
                    )}
                  </Col>
                  <Col>
                    <p
                      className="text-fw-400-fs-16 fw-bold mb-2"
                      style={{ color: '#1B1D1F' }}
                    >
                      Upload back image
                    </p>
                    {registrationCertificateDocument.backImage ? (
                      <img
                        className="ht-277 pt-3 w-100"
                        src={
                          dataTransferService.getBloburl() +
                          registrationCertificateDocument.backImage
                        }
                        alt="Back image"
                        onClick={() =>
                          handleImageClick(
                            [
                              dataTransferService.getBloburl() +
                                registrationCertificateDocument.backImage
                            ],
                            0
                          )
                        }
                      />
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: '100px' }}
                      >
                        <p className="mb-0 text-fw-400 fs-16">NO DATA FOUND</p>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '100px' }}
            >
              <p className="mb-0 text-fw-400 fs-16">NO DATA FOUND</p>
            </div>
          )}
        </Card.Body>
      </Card>

      <Card className="driverDetails my-3">
        <Card.Header className="d-flex justify-content-between p-3">
          <p className="text-fw-400 fs-19 mb-0" style={{ color: '#000' }}>
            {documentTitle}
          </p>
          {displayDocument && (
            <DocumentStatusButton
              document={displayDocument}
              handleApprove={handleApprove}
              handleReject={handleReject}
            />
          )}
        </Card.Header>
        <Card.Body className="position-relative bg-color-gray">
          {displayDocument &&
          (displayDocument.frontImage || displayDocument.backImage) ? (
            <div className="pt-2">
              <div className="mb-2">
                <p className="text-fw-400 fs-16 mb-2">
                  Card type: {displayDocument?.documentType}
                </p>
                <p className="text-fw-400 fs-16 mb-2">
                  Number: {displayDocument?.documentNumber}
                </p>
              </div>
              <Row>
                <Col>
                  <p
                    className="text-fw-400-fs-16 fw-bold mb-2"
                    style={{ color: '#1B1D1F' }}
                  >
                    Upload front image
                  </p>
                  {displayDocument?.frontImage ? (
                    <img
                      className="ht-277 pt-3 w-100"
                      src={
                        dataTransferService.getBloburl() +
                        displayDocument.frontImage
                      }
                      alt="Front image"
                      onClick={() =>
                        handleImageClick(
                          [
                            dataTransferService.getBloburl() +
                              displayDocument.frontImage
                          ],
                          0
                        )
                      }
                    />
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: '100px' }}
                    >
                      <p className="mb-0 text-fw-400-fs-16">NO DATA FOUND</p>
                    </div>
                  )}
                </Col>
                <Col>
                  <p
                    className="text-fw-400-fs-16 fw-bold mb-2"
                    style={{ color: '#1B1D1F' }}
                  >
                    Upload back image
                  </p>
                  {displayDocument?.backImage ? (
                    <img
                      className="ht-277 pt-3 w-100"
                      src={
                        dataTransferService.getBloburl() +
                        displayDocument.backImage
                      }
                      alt="Back image"
                      onClick={() =>
                        handleImageClick(
                          [
                            dataTransferService.getBloburl() +
                              displayDocument.backImage
                          ],
                          0
                        )
                      }
                    />
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: '100px' }}
                    >
                      <p className="mb-0 text-fw-400-fs-16">NO DATA FOUND</p>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '100px' }}
            >
              <p className="mb-0 text-fw-400-fs-16">NO DATA FOUND</p>
            </div>
          )}
        </Card.Body>
      </Card>

      <ImagePreviewComponent
        show={showPreview}
        images={previewImages}
        currentIndex={currentIndex}
        handleClose={handleClose}
        handlePrev={handlePrev}
        handleNext={handleNext}
      />
    </div>
  );
}
export default UnapprovedDetails;
